* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* body {
  background-image: url(./bg_image/pexels-mihai-vlasceanu-1386484.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
} */
