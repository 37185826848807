.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.filterContainer {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10%;
}
.optionsContainer {
  width: 15vw;
}

@media (max-width: 767px) {
  .filterContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .optionsContainer {
    width: 75%;
    align-self: flex-start;
  }
}
