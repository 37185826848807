.chart {
  margin: 10px 20px;
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-grow: 1;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}

.chartTitle {
  margin-bottom: 20px;
}

/* .recharts-wrapper {
  height: 250px !important;
  width: 150px !important;
} */
.recharts-surface {
  max-width: 75vw !important;
  width: fit-content !important;
}
.recharts-default-legend {
  max-width: 60vw !important;
  align-items: center;
}
.recharts-responsive-container {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.mainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subDiv1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.subDiv2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 10px;
}
/* .MuiSelect-select.MuiSelect-select {
  padding-right: 20px !important;
} */
.goButton {
  height: 35px !important;
  width: 35px !important;
  border-radius: 50% !important;
  background-color: #5e2dd8 !important;
  color: #fff;
  margin-left: 10px;
  font-size: 13px !important;
  font-weight: bolder !important;
  display: flex !important; /* or inline-flex */
  align-items: center !important;
  justify-content: center !important;
}
.monthSelect {
  display: flex;
  justify-content: flex-end;
  width: 8vw;
  height: 20px;
}
.graphFilters {
  width: 20vw;
  margin-left: 10px;
  margin-bottom: 10px;
}

@media (max-width: 500px) {
  .mainDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .graphFilters {
    width: 90%;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
