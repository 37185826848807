.search {
  /* padding: 8px; */
  padding: 8px 8px 8px 22px;
  font-family: "Urbanist";
  font-weight: 400;
  font-size: 17px;
  height: 36px;
  width: 28vw;
  text-indent: 20px;
  border-radius: 10px;
  background-color: #fafafa;
  background-image: url("../../../Assets/Images/magnifyingglass.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-x: 2px;
  background-position-y: 8px;
  border-color: #fafafa;
  border-top: #fafafa;
  border-left: #fafafa;
}

.searchWeb {
  /* padding: 8px; */
  padding: 8px 8px 8px 22px;
  font-family: "Urbanist";
  font-weight: 400;
  font-size: 17px;
  height: 36px;
  width: 35vw;
  text-indent: 12px;
  border-radius: 10px;
  background-color: #fafafa;
  background-image: url("../../../Assets/Images/magnifyingglass.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-x: 2px;
  background-position-y: 8px;
  border-color: #fafafa;
  border-top: #fafafa;
  border-left: #fafafa;
}

.countriesList-container {
  width: 35vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin: 8px;
  color: #fff;
}

.country-container {
  width: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #5e2dd8;
  font-size: 75%;
  border-radius: 5px;
}

/* custom searchbar */
.input-wrapper {
  width: 100%;
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  /* box-shadow: 0px 0px 8px #b4b4b4; */
  background-color: #eee;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#geoInput {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 15px;
  width: 100%;
  margin-left: 5px;
}

input:focus {
  outline: none;
}

#search-icon {
  color: gray;
}

.results-list {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* box-shadow: 0px 0px 8px #ddd; */
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: auto;
  z-index: 100;
}

.search-result {
  padding: 10px 20px;
}

.search-result:hover {
  background-color: #efefef;
}

.more-options {
  color: #5e2dd8;
  font-size: 12px;
  /* cursor: pointer; */
}

.custom-control {
  height: "30px";
  padding: 0 6px;
  display: "flex";
  justify-content: "space-between";
  gap: "10px";
  color: rgb(131, 145, 161);
}

@media (max-width: 900px) {
  .searchWeb {
    padding: 8px 8px 8px 30px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    height: 36px;
    width: 50vw;
    text-indent: 12px;
    border-radius: 18px;
    background-color: #fafafa;
    background-image: url("../../../Assets/Images/magnifyingglass.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position-x: 15px;
    background-position-y: 8px;
    border-color: #fafafa;
    border-top: #fafafa;
    border-left: #fafafa;
  }

  .input-wrapper {
    width: 100%;
    height: 2.5rem;
    border: none;
    border-radius: 10px;
    padding: 0 15px;
    box-shadow: 0px 0px 8px #b4b4b4;
    background-color: #eee;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
