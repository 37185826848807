#form-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.formHalf {
  width: 40%;
}
.addCard {
  max-width: 100%;
  width: 80%;
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(74, 58, 255, 0.03),
    0px -2px 4px rgba(20, 20, 43, 0.12), 0px 12px 44px rgba(20, 20, 43, 0.34) !important;
  border-radius: 32px;
}
.addInputField {
  margin-bottom: 15px;
}
.validationMsg {
  font-size: 14px;
  color: red;
  margin-bottom: 0px;
  margin-top: 2px;
}
.disabledField {
  color: #8391a1;
}
.disabledFieldText {
  color: #8391a1 !important;
}

.addUserCard {
  max-width: 100%;
  width: 50%;
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(74, 58, 255, 0.03),
    0px -2px 4px rgba(20, 20, 43, 0.12), 0px 12px 44px rgba(20, 20, 43, 0.34) !important;
  border-radius: 32px;
}
.addUserFormContainer {
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.addUserInput {
  border-radius: 8px;
  background-color: #eeeeee !important;
}
.addUserButtonContainer {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Urbanist" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  gap: 12px;
}
.addUserCancelButton {
  font-family: "DM Sans" !important;
  font-weight: 600 !important;
  font-size: 15px;
  background-color: #d9d9d9 !important;
  border-radius: 10px !important;
  width: 10vw !important;
  height: 40px;
  color: #000 !important;
  border: none !important;
}
.addUserSaveButton {
  font-family: "DM Sans" !important;
  font-weight: 600 !important;
  font-size: 15px;
  background-color: #6f38c5 !important;
  border-radius: 10px !important;
  width: 10vw !important;
  height: 40px;
  color: #fff !important;
  border: none !important;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  margin-left: 7%;
}
@media (max-width: 700px) {
  .addUserCancelButton {
    font-family: "DM Sans" !important;
    font-weight: 600 !important;
    font-size: 15px;
    background-color: #d9d9d9 !important;
    border-radius: 10px !important;
    width: 25vw !important;
    height: 40px;
    color: #000 !important;
    border: none !important;
  }
  .addUserSaveButton {
    font-family: "DM Sans" !important;
    font-weight: 600 !important;
    font-size: 15px;
    background-color: #6f38c5 !important;
    border-radius: 10px !important;
    width: 25vw !important;
    height: 40px;
    color: #fff !important;
    border: none !important;
  }
  .addUserCard {
    max-width: 100%;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 14px rgba(74, 58, 255, 0.03),
      0px -2px 4px rgba(20, 20, 43, 0.12), 0px 12px 44px rgba(20, 20, 43, 0.34) !important;
    border-radius: 32px;
  }
}
