//
// _footer.scss
//

// .footer {
//     bottom: 0;
//     padding: 20px calc(#{$grid-gutter-width} / 2);
//     position: absolute;
//     right: 0;
//     color: $footer-color;
//     left: 250px;
//     height: 20px;
//     background-color: $footer-bg;

//     @media (max-width: 991.98px) {
//         left: 0;
//     }
// }

.footerltrClass {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: 0px;
  height: 20px;
  background-color: $footer-bg;

  @media (max-width: 991.98px) {
    left: 0;
  }
}

.footerrtlClass {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  right: 0px;
  color: $footer-color;
  left: 0;
  height: 20px;
  background-color: $footer-bg;

  @media (max-width: 991.98px) {
    right: 0;
  }
}

// Enlarge menu ltr
.vertical-collpsed {
  .footer {
    left: 70px;

    @media (max-width: 991.98px) {
      left: 0;
    }
  }
}

// Enlarge menu rtl
.vertical-collpsed {
  .footer {
    right: 70px;

    @media (max-width: 991.98px) {
      right: 0;
    }
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}
