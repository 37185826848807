.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}
.switch-input {
  opacity: 0;
  top: 2%;
  left: 2%;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9f9f9f;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  bottom: 3px;
  left: 3px;
  background-color: #ecf0f1;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translateX(16px);
}
input:checked + .slider {
  background-color: #6f38c5;
}

/**************************** react-toggle *******************************/

/* These are example-specific */
/* 
.react-toggle {
  margin-right: 8px;
  vertical-align: middle;
}

label,
.label-text {
  vertical-align: middle;
  font-weight: normal;
  margin-bottom: 0; 
}

.example {
  margin-top: 36px;
  margin-bottom: 36px;
}

pre {
  margin-top: 8px;
}

.sliderColor.react-toggle--checked .react-toggle-track {
  background-color: #6f38c5 !important;
}
.react-toggle-track {
  background-color: #9f9f9f !important;
}
.react-toggle--focus {
  display: none !important;
  box-shadow: none !important;
} */
