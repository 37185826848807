.home {
  display: flex;
  flex: 4;
  max-height: 50%;
  height: fit-content;
  flex-direction: column;
}

.homeWidgets {
  display: flex;
  margin: 20px;
}
