.featured {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.featuredItem {
  flex: 1;
  max-width: 282px;
  max-height: 140px;
  margin: 5px 5px;
  padding: 8px 15px;
  border-radius: 16px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.featuredMoneyContainer {
  margin: 10px 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.featuredMoney {
  font-size: 20px;
  font-weight: 600;
  font-family: "poppins";
  color: #1e2029;
  margin-bottom: 10px;
}

.featuredMoneyRate {
  display: flex;
  align-items: center;
  font-family: "poppins";
  font-size: 12px;
  font-weight: 400;
  color: #1e2029;
}

.featuredIcon {
  color: #6f38c5;
  width: 80px;
  height: 80px;
}
.featuredLogo {
  color: #6f38c5;
  width: 70px;
  height: 70px;
}

.featuredSub {
  font-size: 15px;
  color: gray;
}
.featuredTitle {
  font-family: "poppins";
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.6;
  margin-bottom: 5px;
}
.featuredMainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
