@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* > * {
  font-family: "Poppins", cursive;
  margin: 0;
}

body {
  background: #959595; /* fallback for old browsers */
}
textarea:hover,
Input:hover,
textarea:active,
Input:active,
textarea:focus,
input:focus,
Input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  box-shadow: none !important;
}

.App {
  /* height: 100vh; */
  margin-top: 10vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.registerLogin {
  max-width: 500px;
  max-height: 731px;
  height: auto;
  /* height: 500px; */
  /* margin-top: 73px; */
  /* height: 102vh; */
  border-radius: 50px;
  width: 95vw;
  display: flex;
  border: 1px solid #e9ecef;
  box-shadow: 0px 3px 14px rgba(74, 58, 255, 0.03),
    0px -2px 4px rgba(20, 20, 43, 0.12), 0px 12px 44px rgba(20, 20, 43, 0.34);
  background-color: white;
  /* background: rgba(255, 255, 255, 0.15); */
}

.registerSignUp {
  max-width: 606px;
  height: 100%;
  width: 100vw;
  display: flex;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  /* box-shadow: 1px 3px 10px #e9ecef; */
  background-color: white;
  border-radius: 10px;
}

.registerTeacher {
  max-width: 75vw;
  height: 98%;
  width: 60vw;
  display: flex;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  box-shadow: 1px 3px 10px #e9ecef;
  background-color: white;
  border-radius: 10px;
}

.register span {
  color: #adb5bd;
}

#formLogin {
  max-width: fit-content;
  width: 80vw !important;
  height: auto;
  margin: 1em auto;
  font-size: small;
}
#formSignUp {
  max-width: 496px;
  /* max-height: 102vh; */
  width: 90vw !important;
  height: fit-content;
  margin: 1em auto;
  font-size: small;
}

#formTeacher {
  max-width: 496px;
  width: 50vw !important;
  height: fit-content;
  margin: 1em;
  font-size: small;
  position: sticky;
}

#form > input,
.btn {
  border: 1px solid #e9ecef;
  padding: 0.9em 1em;
}
#formSignUp > input,
.btn {
  border: 1px solid #e9ecef;
  padding: 0.9em 1em;
}

#form > input:focus {
  outline: none;
}
#formSignUp > input:focus {
  outline: none;
}

#form > .btn {
  background-color: #5e2dd8;
  color: #f1f1f9;
  font-size: 1em;
  cursor: pointer;
  max-width: 496px;
  width: 90vw;
  max-height: 64px;
}
#formSignUp > .btn {
  background-color: #5e2dd8;
  color: #f1f1f9;
  font-size: 1em;
  cursor: pointer;
  max-width: 496px;
  width: 90vw;
  max-height: 64px;
}

.flex {
  display: flex;
  gap: 1em;
}

.flex-col {
  flex-direction: column;
}

.register .col-1 {
  margin: auto;
  padding: 3em 0;
}

.register .col-2 img {
  width: 480px;
  height: 100%;
  object-fit: cover;
  align-self: flex-end;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}

@media screen and (min-width: 860px) {
  .register .col-2 img {
    display: initial;
  }
}

.error {
  color: red;
  font-size: smaller;
}

.textfield {
  color: #5e2dd8;
}

.container {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}
.others {
  flex: 4;
}

.link {
  text-decoration: none;
  color: inherit;
}
* {
  font-family: "Source Sans Pro", sans-serif;
}
.app {
  display: flex;
  flex-direction: row;
}
.container2 {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  flex: 1;
  height: 50vh;
}
.btnLgIn {
  background-color: #5e2dd8;
  color: #f1f1f9;
  font-size: 1.5em;
  cursor: pointer;
  max-width: 375px;
  width: 80vw;
  padding: 5px;
  max-height: 64px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.labelltrClass {
  color: #5e2dd8;
  float: left;
  font-weight: 400;
}
.labelrtlClass {
  color: #5e2dd8;
  float: right;
  font-weight: 400;
}
.submitbtnltrClass {
  background-color: #008000;
  color: white;
  cursor: pointer;
  max-width: 496px;
  width: 90vw;
  max-height: 75px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  height: 35px;
  margin-left: 10px;
}
.submitbtnrtlClass {
  background-color: #008000;
  color: white;
  cursor: pointer;
  max-width: 496px;
  width: 90vw;
  max-height: 75px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  height: 35px;
  margin-right: 10px;
}
.cancelbtnltrClass {
  background-color: #cf142b;
  color: white;
  width: "100px";
  cursor: pointer;
  max-width: 496px;
  width: 90vw;
  max-height: 75px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  height: 35px;
  margin-right: 10px;
}
.cancelbtnrtlClass {
  background-color: #cf142b;
  color: white;
  width: "100px";
  cursor: pointer;
  max-width: 496px;
  width: 90vw;
  max-height: 75px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  height: 35px;
  margin-left: 10px;
}

.password {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  margin-bottom: 10px;
}
.passwordCol {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin-bottom: 10px;
}

.passwordBtn {
  border: none;
  outline: none;
  margin-left: -42px;
  width: auto;
  border-radius: 3px;
  background-color: transparent;
  font-size: x-large !important;
  color: #5e2dd8;
  cursor: pointer;
}
.fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fieldIcon {
  border: none;
  outline: none;
  margin-left: -42px;
  width: 30px;
  border-radius: 3px;
  background-color: transparent;
  font-size: x-large !important;
  color: #5e2dd8;
  /* margin-top: -35px; */
  float: right;
}
.signUpFields {
  width: 100% !important;
  height: "20px";
  background-color: #f1f1f9 !important;
}
.passwordFieldIcon {
  border: none;
  outline: none;
  /* margin-left: -42px !important; */
  width: 30px !important;
  border-radius: 3px !important;
  background-color: transparent;
  font-size: x-large !important;
  color: #5e2dd8;
  margin-top: -20px !important;
  float: right !important;
}
.passworSignUpBtn {
  border: none;
  outline: none;
  margin-left: -42px;
  width: auto;
  border-radius: 3px;
  background-color: transparent;
  font-size: x-large !important;
  color: #5e2dd8;
  cursor: pointer;
  float: right;
  margin-top: -20px !important;
}
.textfieldTeacher {
  width: 50vw !important;
  height: "20px";
  background-color: #f1f1f9 !important;
}
/* p {
  margin-top: 0;
  margin-bottom: 2px;
} */
.sidebarListText {
  font-size: 16px;
  font-weight: 500;
  font-family: "Urbanist";
}

.sidebarIconltrClass {
  margin-right: 15px;
  font-size: 20px !important;
  align-self: center;
  width: "20px";
  height: "20px";
}
.sidebarIconrtlClass {
  margin-left: 16px;
  font-size: 20px !important;
  width: "20px";
  height: "20px";
}

.activeTab > .sidebarListItem,
.sidebarListItem:hover {
  /* background-color: rgb(240, 240, 255); */
  color: #6f38c5 !important;
}
.sidebarListItem::after {
  color: #6f38c5 !important;
  /* background-color: rgb(240, 240, 255); */
}
.sidebarListItem {
  /* max-width: fit-content; */
  display: flex !important;
  flex-direction: row;
  color: #5c5c5c !important;
  align-items: center;
}
/* .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
  display: none !important;
} */
.forgotPasswordAfter {
  max-width: 606px;
  height: 97vh;
  width: 100vw;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  /* box-shadow: 1px 3px 10px #e9ecef; */
  background-color: white;
  border-radius: 10px;
}

.tableActionIcon {
  margin: 5px;
  z-index: 99;
}

.tableActionIcon:hover {
  /* margin: 5px; */
  scale: 5;
  cursor: hand;
  /* z-index: 99 !impo/rtant; */
}

/* input[type="checkbox"] {
  align-self: center;
  height: 20px;
  width: 20px;
  border: 1px solid #6f38c5 !important;
  border-radius: 5px !important;
} */
.action {
  display: flex;
  justify-content: center !important;
}
.rfp {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.inputFieldColor {
  background-color: #eeeeee !important;
  padding: 0.375rem 2.5rem 0.375rem 0.75rem !important;
}
.searchAndFilter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 52vw;
  height: 100%;
}
.sort-container {
  min-height: 20px !important;
  width: 46%;
  margin: 2px 0px 2px 10px;
}
.sort-container-web {
  min-height: 20px !important;
  width: 20% !important;
  margin-left: 10px !important;
}
.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background: linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.7));
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/*********************Common style css ****************************/

.tableHeader {
  background-color: #f8fafb;
}
.tableHeaderCell {
  font-family: "Poppins" !important;
  font-size: 14px;
  font-weight: 500;
  color: black;
  gap: 10px;
}
.headerIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  cursor: pointer;
}
.coins {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
}
.rowCell {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #464e5f !important;
  line-height: 18px !important;
}
.addButton {
  display: flex;
  justify-content: space-evenly;
  background-color: #6f38c5 !important;
  color: #f1f1f9;
  font-size: 1.5em;
  cursor: pointer;
  max-width: 125px;
  width: 100%;
  max-height: 64px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
}
.applyButton {
  display: flex;
  justify-content: space-evenly;
  background-color: #6f38c5 !important;
  color: #f1f1f9;
  font-size: 14px;
  cursor: pointer;
  max-width: 15vw;
  width: 10vw;
  max-height: 64px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
}
.addBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: "Urbanist" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.saveButtonContainer {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Urbanist" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.saveButton {
  background-color: #6f38c5 !important;
  border-radius: 10px !important;
  width: 10vw !important;
}
.deleteButton {
  font-family: "DM Sans" !important;
  font-weight: 400 !important;
  background-color: #6f38c5 !important;
  border-radius: 10px !important;
  width: 8vw !important;
  color: #fff !important;
}
.cancelButton {
  font-family: "DM Sans" !important;
  font-weight: 400 !important;
  background-color: #d9d9d9 !important;
  border-radius: 10px !important;
  width: 8vw !important;
  color: #000 !important;
}
.modalButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modalIconDiv {
  width: 140px;
  height: 140px;
  background: #6f38c5;
  opacity: 0.1;
  border-radius: 39.2683px;
}
.paymentStatusSuccess {
  color: #42ba17;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
}
.paymentStatusFailed {
  color: #d2001a;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
}
.paymentStatusPending {
  color: #ffde00;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
}
Input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid #6f38c5 !important;
}
.pageHeading {
  display: flex;
  align-items: center;
  font-family: "Urbanist";
  font-size: 24px;
  font-weight: 600;
  color: #5c5c5c;
  margin-left: 10px;
}
.headerHalfContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60vw;
}
.headerHalfContainerHeaderOption {
  display: flex;
  align-items: center;
}

.noTableData {
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-family: "Montserrat", "Helvetica Neue";
}
.role-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.role-subContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.role-checkbox-container {
  display: inline-flex;
  gap: 5px;
}

@media (max-width: 705px) {
  .pageHeading {
    display: flex;
    align-items: center;
    font-family: "Urbanist";
    font-size: 12px;
    font-weight: 800;
    color: #5c5c5c;
    /* margin-left: -10px; */
  }
  .saveButton {
    background-color: #6f38c5 !important;
    border-radius: 10px !important;
    width: 24vw !important;
  }
  .applyButton {
    display: flex;
    justify-content: space-evenly;
    background-color: #6f38c5 !important;
    color: #f1f1f9;
    font-size: 14px;
    cursor: pointer;
    max-width: 20vw;
    width: 20vw;
    max-height: 64px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }
}
@media (max-width: 998px) {
  .deleteButton {
    font-family: "DM Sans" !important;
    font-weight: 400 !important;
    background-color: #6f38c5 !important;
    border-radius: 10px !important;
    width: 24vw !important;
    color: #fff !important;
  }
  .cancelButton {
    font-family: "DM Sans" !important;
    font-weight: 400 !important;
    background-color: #d9d9d9 !important;
    border-radius: 10px !important;
    width: 24vw !important;
    color: #000 !important;
  }
}

.sidebarListItem > svg > path:hover {
  fill: #6f38c5 !important;
}
.activeTab > .sidebarListItem > svg > path:active {
  fill: #6f38c5 !important;
}
.sidebarListItem:hover {
  fill: #6f38c5 !important;
}
