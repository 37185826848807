.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.back-button-container {
    width: 50%;
    text-align: center;
}

.back-button {
    width: 10vw !important;
    height: auto;
    border-radius: 15px !important;
    border: 0.5px solid #6f38c5;
    color: #fff !important;
    background-color: #6f38c5 !important;
}

.back-button:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
}

.back-button:hover {
    box-shadow: 0px 10px 10px #00000029 !important;
}