.tableHeader {
  background-color: #f8fafb;
}

.tableHeaderCell {
  font-family: "Poppins" !important;
  font-size: 14px;
  font-weight: 500;
  color: black;
  gap: 10px;
}

.headerIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  cursor: pointer;
}

.coins {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.rowCell {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #464e5f !important;
  line-height: 18px !important;
}

.addButton {
  display: flex;
  justify-content: space-evenly;
  background-color: #6f38c5 !important;
  color: #f1f1f9;
  font-size: 1.5em;
  cursor: pointer;
  max-width: 125px;
  width: 100%;
  max-height: 64px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
}

.applyButton {
  display: flex;
  justify-content: space-evenly;
  background-color: #6f38c5 !important;
  color: #f1f1f9;
  font-size: 14px;
  cursor: pointer;
  max-width: 15vw;
  width: 10vw;
  max-height: 64px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
}

.addBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: "Urbanist" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.saveButtonContainer {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Urbanist" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.saveButton {
  background-color: #6f38c5 !important;
  border-radius: 10px !important;
  width: 10vw !important;
}

.deleteButton {
  font-family: "DM Sans" !important;
  font-weight: 400 !important;
  background-color: #6f38c5 !important;
  border-radius: 10px !important;
  width: 8vw !important;
  color: #fff !important;
}

.cancelButton {
  font-family: "DM Sans" !important;
  font-weight: 400 !important;
  background-color: #d9d9d9 !important;
  border-radius: 10px !important;
  width: 8vw !important;
  color: #000 !important;
}

.modalButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.modalIconDiv {
  width: 140px;
  height: 140px;
  background: #6f38c5;
  opacity: 0.1;
  border-radius: 39.2683px;
}

.paymentStatusSuccess {
  color: #42ba17;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
}

.paymentStatusFailed {
  color: #d2001a;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
}

.paymentStatusPending {
  color: #ffde00;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
}

Input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid #6f38c5 !important;
}

.pageHeading {
  display: flex;
  align-items: center;
  font-family: "Urbanist";
  font-size: 24px;
  font-weight: 600;
  color: #5c5c5c;
  margin-left: 10px;
}

.headerHalfContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60vw;
}

.headerHalfContainerHeaderOption {
  display: flex;
  align-items: center;
}

.noTableData {
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-family: "Montserrat", "Helvetica Neue";
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

@media (max-width: 705px) {
  .pageHeading {
    display: flex;
    align-items: center;
    font-family: "Urbanist";
    font-size: 12px;
    font-weight: 800;
    color: #5c5c5c;
    /* margin-left: -10px; */
  }

  .saveButton {
    background-color: #6f38c5 !important;
    border-radius: 10px !important;
    width: 24vw !important;
  }

  .applyButton {
    display: flex;
    justify-content: space-evenly;
    background-color: #6f38c5 !important;
    color: #f1f1f9;
    font-size: 14px;
    cursor: pointer;
    max-width: 20vw;
    width: 20vw;
    max-height: 64px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }
}

@media (max-width: 998px) {
  .deleteButton {
    font-family: "DM Sans" !important;
    font-weight: 400 !important;
    background-color: #6f38c5 !important;
    border-radius: 10px !important;
    width: 24vw !important;
    color: #fff !important;
  }

  .cancelButton {
    font-family: "DM Sans" !important;
    font-weight: 400 !important;
    background-color: #d9d9d9 !important;
    border-radius: 10px !important;
    width: 24vw !important;
    color: #000 !important;
  }
}

.sidebarListItem>svg>path:hover {
  fill: #6f38c5 !important;
}

.activeTab>.sidebarListItem>svg>path:active {
  fill: #6f38c5 !important;
}

.sidebarListItem:hover {
  fill: #6f38c5 !important;
}